import { Dictionary } from 'app/shared/data.structures';
import { sortByName } from '../../shared/ui-helper.service';
import { SettingVM } from 'app/shared/models/settings-vm';

export interface ISyncProgress {
  Id: number;
  Message: string;
  SyncKey: string;
  HasDbError: boolean;
  HasFileError: boolean;
  IsActive: boolean;
  FileSyncProgress: number;
  FileOperations: string[];
  FileTime: string;
  ErrorMessage: string;
  DbSyncProgress: number;
  DatabaseOperations: string[];
  DatabaseTime: string;
  FileErrorMessage: string;
  ModifiedEntities: Array<IModifiedEntity>;
}

export interface IModifiedEntity {
  EntityType: string;
  EntityId: number;
  Name: string;
  Action: string;
  ModifiedProperties: Array<IEntityProperty>;
  MasterProperties: Dictionary<string>;
}

export interface IEntityProperty {
  Name: string;
  Master: string;
  Slave: string;
}

export class EntityProperty implements IEntityProperty {
  Name: string;
  Master: string;
  Slave: string;

  constructor(prop?: IEntityProperty) {
    if (prop) {
      this.Name = prop.Name;
      this.Master = prop.Master;
      this.Slave = prop.Slave;
    }
  }
}

export class PublishEntityVM {
  Name: string;
  Id: number;
  Type: string;
  Action: string;
  ModifiedProperties = new Array<EntityProperty>();
  MasterProperties = {};
  allowFreeze = false;
  freeze = false;
  alreadyFrozen = false;

  constructor(entity?: IModifiedEntity) {
    if (entity) {
      this.Id = entity.EntityId;
      this.Name = entity.Name || `${entity.EntityType} ${entity.EntityId}`;
      this.Type = entity.EntityType;
      this.Action = this.formatActionName(entity);
      this.allowFreeze = this.initAllowFreeze(entity);
      this.ModifiedProperties = this.convertModifiedProperties(entity.ModifiedProperties);
      this.MasterProperties = entity.MasterProperties;
      this.ModifiedProperties.sort(sortByName);
    }
  }

  private formatActionName(entity: IModifiedEntity): string {
    return entity.Action && entity.Action.length
      ? entity.Action.charAt(0).toUpperCase() + entity.Action.slice(1)
      : '';
  }

  private initAllowFreeze(entity): boolean {
    return (
      entity.EntityType.toLowerCase() === 'floorplan' ||
      entity.EntityType.toLowerCase() === 'neighborhood'
    );
  }

  private convertModifiedProperties(
    modifiedProperties: Array<EntityProperty>
  ): Array<EntityProperty> {
    let result = new Array<EntityProperty>();

    modifiedProperties.forEach(prop => {
      const newProp = new EntityProperty(prop);

      if (prop.Name === 'FreezeSync') {
        this.freeze = this.alreadyFrozen = true;
      }

      if (prop.Name !== 'ContentJson') result.push(newProp);
    });

    const contentJsonModProp = modifiedProperties.find(p => p.Name === 'ContentJson');
    if (contentJsonModProp)
      result = [...result, ...this.ContentJsonToEntityProperties(contentJsonModProp)];

    return result;
  }

  private ContentJsonToEntityProperties(contentJsonModProp: EntityProperty): Array<EntityProperty> {
    const result = new Array<EntityProperty>();
    const masterContentJson = contentJsonModProp.Master
      ? JSON.parse(contentJsonModProp.Master)
      : {};
    const slaveContentJson = contentJsonModProp.Slave ? JSON.parse(contentJsonModProp.Slave) : {};

    for (const prop in masterContentJson) {
      if (Array.isArray(masterContentJson[prop])) {
        let count = 1;
        masterContentJson[prop].forEach(p => {
          if (typeof p === 'object') {
            const newProp = new EntityProperty();
            newProp.Name = prop + count;
            newProp.Master = JSON.stringify(p);
            newProp.Slave = JSON.stringify(p);
            ++count;
            result.push(newProp);
          } else {
            const newProp = new EntityProperty();
            newProp.Name = prop;
            newProp.Master = this.isNullOrUndefined(p) ? '<blank>' : p.toString();
            newProp.Slave = this.isNullOrUndefined(p) ? '<blank>' : p.toString();
            result.push(newProp);
          }
        });
      } else if (typeof masterContentJson[prop] === 'object') {
        const newProp = new EntityProperty();
        newProp.Name = prop;
        newProp.Master = this.isNullOrUndefined(masterContentJson[prop])
          ? '<blank>'
          : JSON.stringify(masterContentJson[prop]);
        newProp.Slave = this.isNullOrUndefined(slaveContentJson[prop])
          ? '<blank>'
          : JSON.stringify(slaveContentJson[prop]);
        result.push(newProp);
      } else {
        const newProp = new EntityProperty();
        newProp.Name = prop;
        newProp.Master = this.isNullOrUndefined(masterContentJson[prop])
          ? '<blank>'
          : masterContentJson[prop].toString();
        newProp.Slave = this.isNullOrUndefined(slaveContentJson[prop])
          ? '<blank>'
          : slaveContentJson[prop].toString();
        result.push(newProp);
      }
    }

    return result;
  }

  private isNullOrUndefined(val: any) {
    return val === undefined || val === null;
  }
}

export class NeighborhoodPublish {
  Id: number;
  Name: string;
  freeze = false;
  alreadyFrozen = false;
  Neighborhood = new Array<PublishEntityVM>();

  constructor(id: number, name?: string) {
    this.Id = id;
    this.Name = name || 'Neighborhood ' + id;
  }
}

export class PublishCategory {
  CategoryName: string;
  PublicName: string;
  SvgName: string;

  constructor(categoryName: string, publicName: string, svg: string) {
    this.CategoryName = categoryName;
    this.PublicName = publicName;
    this.SvgName = svg;
  }
}

export class Publish {
  Id: number;
  IsActive = false;
  Progress = 0;
  FileSyncProgress = 0;
  Categories = new Array<PublishCategory>();

  Pages = new Array<PublishEntityVM>();
  Theming = new Array<PublishEntityVM>();
  Home = new Array<PublishEntityVM>();
  AreaMap = new Array<PublishEntityVM>();
  FloorPlans = new Array<PublishEntityVM>();
  Brochure = new Array<PublishEntityVM>();
  Neighborhoods = new Array<NeighborhoodPublish>();

  constructor(id: number, diff?: ISyncProgress, allSettings?: SettingVM[]) {
    try {
      this.Id = id;
      if (diff && diff.ModifiedEntities) {
        for (const entity of diff.ModifiedEntities) {
          switch (entity.EntityType.toLowerCase()) {
            case 'floorplan':
              this.addFloorPlan(entity);
              break;
            case 'page':
              if (entity.Name === 'Area Map') this.addAreaMap(entity);
              else this.addPage(entity);
              break;
            case 'settings':
              this.addSetting(entity, allSettings);
              break;
            case 'community':
              this.addCommunityToAreaMap(entity);
              break;
            case 'lot':
            case 'lottoelevation':
            case 'lottofloorplan':
            case 'neighborhood':
            case 'amenity':
            case 'amenitymap':
            case 'lotmapstatus':
              this.addNeighborhood(entity);
              break;
          }
        }
      }

      this.Pages.sort(sortByName);
      this.Theming.sort(sortByName);
      this.Home.sort(sortByName);
      this.AreaMap.sort(sortByName);
      this.FloorPlans.sort(sortByName);
      this.Brochure.sort(sortByName);

      for (const neighborhoodPub of this.Neighborhoods)
        neighborhoodPub.Neighborhood.sort(sortByName);
    } catch (e) {
      console.log('Error', e);
    }

    this.Categories.push(new PublishCategory('Pages', 'Pages', 'gear'));
    this.Categories.push(new PublishCategory('Home', 'Home', 'scp_house'));
    this.Categories.push(new PublishCategory('AreaMap', 'Area Map', 'management_maps_nav'));
    this.Categories.push(new PublishCategory('Neighborhood', 'Community Map', 'site_map'));
    this.Categories.push(new PublishCategory('FloorPlans', 'Floor Plans', 'ifp'));
    this.Categories.push(new PublishCategory('Brochure', 'Brochure', 'gear'));
  }

  addAreaMap(entity: IModifiedEntity) {
    this.AreaMap.push(new PublishEntityVM(entity));
  }

  addCommunityToAreaMap(entity: IModifiedEntity) {
    entity.ModifiedProperties = entity.ModifiedProperties.filter(p => {
      switch (p.Name.toLowerCase()) {
        case 'address':
        case 'city':
        case 'state':
        case 'zip':
        case 'postalcode':
        case 'salescenterphonenumber':
        case 'latitude':
        case 'longitude':
        case 'describe':
          return true;
        default:
          return false;
      }
    });

    this.AreaMap.push(new PublishEntityVM(entity));
  }

  addFloorPlan(entity: IModifiedEntity) {
    this.FloorPlans.push(new PublishEntityVM(entity));
  }

  addPage(entity: IModifiedEntity) {
    this.Pages.push(new PublishEntityVM(entity));
  }

  addNeighborhood(entity: IModifiedEntity) {
    const neighborhoodId = parseInt(entity.MasterProperties['NeighborhoodId'], 10);

    if (neighborhoodId) {
      let neighborhoodPublish = this.Neighborhoods.find(n => n.Id === neighborhoodId);
      if (!neighborhoodPublish) {
        neighborhoodPublish = new NeighborhoodPublish(neighborhoodId);
        this.Neighborhoods.push(neighborhoodPublish);
      }

      if (
        entity.MasterProperties['FreezeSync'] &&
        entity.MasterProperties['FreezeSync'].toLowerCase() === 'true'
      ) {
        neighborhoodPublish.alreadyFrozen = true;
        neighborhoodPublish.freeze = true;
      }

      neighborhoodPublish.Neighborhood.push(new PublishEntityVM(entity));
    }
  }

  addSetting(entity: IModifiedEntity, allSettings?: SettingVM[]) {
    const setting = allSettings.find(
      cat => cat.SettingsCategoryId === parseInt(entity.MasterProperties['SettingsCategoryId'], 10)
    );
    const name = `${entity.Name} ${this.getSettingTierOverrideName(entity)}`;

    const newEntity: IModifiedEntity = {
      EntityType: 'Setting',
      EntityId: entity.EntityId,
      Name: name,
      Action: entity.Action,
      ModifiedProperties: new Array<IEntityProperty>(),
      MasterProperties: new Dictionary<string>()
    };

    newEntity.ModifiedProperties = entity.ModifiedProperties.filter(
      prop => prop.Name === 'ModifiedOn' || prop.Name === 'Value'
    );

    if (setting) {
      switch (setting.SettingsCategoryName) {
        case 'MyScp - Theming':
          this.Theming.push(new PublishEntityVM(newEntity));
          break;
        case 'MyScp - Home page':
          this.Home.push(new PublishEntityVM(newEntity));
          break;
        case 'MyScp - Area Map':
          this.AreaMap.push(new PublishEntityVM(newEntity));
          break;
        case 'MyScp - Community Map':
          this.addNeighborhood(newEntity);
          break;
        case 'MyScp - Brochure':
          this.Brochure.push(new PublishEntityVM(newEntity));
          break;
      }
    }
  }

  getSettingTierOverrideName(entity: IModifiedEntity): string {
    if (entity.MasterProperties['CommunityId'] !== null) return '(Community)';

    if (entity.MasterProperties['ProjectId'] !== null) return '(Project)';

    return '';
  }
}
