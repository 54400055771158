import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Lookup } from 'app/shared/lookup';
import { Observable, interval, of } from 'rxjs';
import { filter, takeUntil, map, switchMap, mergeMap } from 'rxjs/operators';
import { Publish, ISyncProgress } from '../models/publish';
import { SettingVM } from 'app/shared/models/settings-vm';

class SyncRequest {
  NeighborhoodFreeze = new Array<number>();
  FloorPlanFreeze = new Array<number>();
  Preview = false;

  constructor(preview: boolean, neighborhoods?: Array<number>, floorplans?: Array<number>) {
    this.Preview = preview;
    if (neighborhoods) this.NeighborhoodFreeze = neighborhoods.map(n => n);

    if (floorplans) this.FloorPlanFreeze = floorplans.map(fpId => fpId);
  }
}

@Injectable()
export class PublishService {
  stageArgoBase = window.location.protocol + '//' + Lookup.Argo.StageHost;

  constructor(private http: HttpClient) {}

  getCommunityPreview(id: number, allSettings: SettingVM[]): Promise<Publish> {
    const api = `${this.stageArgoBase}/api/communities/${id}/sync`;
    return this.http
      .post<ISyncProgress>(api, new SyncRequest(true))
      .pipe<Publish>(map((progress: ISyncProgress) => new Publish(id, progress, allSettings)))
      .toPromise();
  }

  publish(publish: Publish, level: SyncLevel): Promise<PublishKey> {
    const floorplanIds = publish.FloorPlans.filter(fp => fp.freeze).map(fp => fp.Id);
    const neighboorIds = publish.Neighborhoods.filter(n => n.freeze).map(n => n.Id);
    const api = `${this.stageArgoBase}${this.getSyncLevelPath(level, publish.Id)}`;

    return this.http
      .post<PublishKey>(api, new SyncRequest(false, neighboorIds, floorplanIds))
      .toPromise();
  }

  getPublishUpdate(id: number, level: SyncLevel): Observable<Publish> {
    const publish = new Publish(id);
    publish.IsActive = true;

    const source = interval(1000);
    const isSyncComplete = () => {
      return publish.Progress >= 100 || !publish.IsActive;
    };

    const syncCompleteSource = source.pipe(filter(isSyncComplete));

    const levelPath = this.getSyncLevelPath(level, id);

    return source.pipe(
      takeUntil(syncCompleteSource),
      switchMap(() => this.http.get<ISyncProgress>(`${this.stageArgoBase}${levelPath}`)),
      mergeMap((progress: ISyncProgress): Observable<Publish> => {
        if (progress.DbSyncProgress > 100) progress.DbSyncProgress = 100;
        if (progress.FileSyncProgress > 100) progress.FileSyncProgress = 100;
        if (this.noFilesFoundToSync(progress)) progress.FileSyncProgress = 100;

        publish.Progress = progress.DbSyncProgress * 0.25 + progress.FileSyncProgress * 0.75;
        publish.IsActive = progress.IsActive;
        publish.FileSyncProgress = progress.FileSyncProgress;

        if (publish.Progress > 99) publish.Progress = 100;

        return of(publish);
      })
    );
  }

  noFilesFoundToSync(progress: ISyncProgress): boolean {
    return (
      progress.FileSyncProgress === 0 &&
      progress.FileOperations.length === 0 &&
      progress.DbSyncProgress === 100
    );
  }

  getSyncLevelPath(level: SyncLevel, id: number): string {
    switch (level) {
      case SyncLevel.Community:
        return `/api/communities/${id}/sync`;
      case SyncLevel.FloorPlanDataAndMedia:
        return `/api/floorplansv2/${id}/media/sync`;
      case SyncLevel.AreaMap:
        return `/api/areamaps/${id}/sync`;
      default:
        throw new Error('Unknown sync found');
    }
  }
}

export class PublishKey {
  Message: string;
  SyncKey: string;
}

export enum SyncLevel {
  Community,
  FloorPlanDataAndMedia,
  AreaMap
}
