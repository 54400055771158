import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BroadcastService {
  private events = new Map<BroadcastEvent, Subject<any>>();

  private initEvent(event) {
    if (this.events.has(event) === false) {
      this.events.set(event, new Subject());
    }

    return this.events.get(event);
  }

  broadcast(event: BroadcastEvent, args?: any) {
    const sub = this.initEvent(event);
    sub.next(args);
  }

  listen(event: BroadcastEvent): Subject<any> {
    return this.initEvent(event);
  }
}

export enum BroadcastEvent {
  Save
}
